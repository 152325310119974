import { Injectable } from '@angular/core';
import {CacheService} from "../../../shared/services/cache.service";
import {LoginVM} from "../../models/loginVM.model";
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {JwtToken} from "../../models/jwtToken.model";
import {environment} from "../../../../environments/environment";
import { JwtHelperService } from '@auth0/angular-jwt';
import {Router} from "@angular/router";
import {User} from "../../models/user.model";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    readonly TOKEN_KEY = "TOKEN";
    readonly UTILISATEUR_KEY = "UTILISATEUR";
    readonly SESSION_DE_CAISSE_KEY = "SESSION_DE_CAISSE";
    readonly API_URL = environment.API_URL;

    constructor(
        private cacheService: CacheService,
        private http: HttpClient,
        private router: Router,
    ) { }

    public getToken(): string {
        return this.cacheService.getData(this.TOKEN_KEY);
    }

    public login(loginVM: LoginVM): Observable<HttpResponse<JwtToken>> {
        return this.http.post<JwtToken>(`${this.API_URL}/authenticate`, loginVM, { observe: "response"});
    }

    public onSaveToken(token: string): void {
        // Save Token
        this.cacheService.saveData(this.TOKEN_KEY, token);

        // Save additional data
        const jwtHelperService = new JwtHelperService();
        const decodedToken = jwtHelperService.decodeToken(token);
        const utilisateurJson = decodedToken['utilisateur'];
        if (utilisateurJson !== undefined) {
            this.cacheService.saveData(this.UTILISATEUR_KEY, JSON.stringify(utilisateurJson));
        }
    }

    public isTokenExpired(): boolean {
        const jwtHelperService = new JwtHelperService();
        return jwtHelperService.isTokenExpired(this.getToken());
    }

    public onLogout(): void {
        this.cacheService.clearData();
        this.router.navigate(['/auth/login']);
    }

    public getLocalUser(): User | null {
        const utilisateurJson = this.cacheService.getData(this.UTILISATEUR_KEY);
        if (utilisateurJson === undefined || utilisateurJson === '')
            return null;
        return JSON.parse(JSON.parse(utilisateurJson)) as User;
    }

    public hasAnyAuthority(authorities: string[]): boolean {
        if (!authorities || authorities.length === 0) {
            return true;
        }
        const jwtHelperService = new JwtHelperService();
        const decodedToken = jwtHelperService.decodeToken(this.getToken());
        const auths: string[] = (decodedToken.auth as string).split(',');
        return auths.some( (auth: string) => authorities.includes(auth));
    }
}
