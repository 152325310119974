import {Component, OnInit} from '@angular/core';
import {UserService} from "../user.service";
import {MessageService} from "primeng/api";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../../../models/user.model";
import {Observable} from "rxjs";
import {Profil} from "../../../models/profil.model";
import {ProfilService} from "../profil.service";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
    formUser!: FormGroup;
    user!: User;

        genres: { key: string, label: string }[] = [
        {
            key: 'MASCULIN',
            label: 'MASCULIN',
        },
        {
            key: 'FEMININ',
            label: 'FEMININ',
        },
    ];
    typeUtilisateurs: { key: string, label: string }[] = [
        {
            key: 'GERANT',
            label: 'GERANT',
        },
        {
            key: 'SUPERVISEUR',
            label: 'SUPERVISEUR',
        },
        {
            key: 'UTILISATEUR',
            label: 'UTILISATEUR',
        },
    ];
    currentTypeUtilisateur!: { key: string, label: string } | undefined;
    currentGenre!: { key: string, label: string } | undefined;
    profils$!: Observable<Profil[]>;

    constructor(
        private userService: UserService,
        private messageService: MessageService,
        private fb: FormBuilder,
        private dialogRef: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig,
        private profilService: ProfilService,
    ) {
    }
    ngOnInit(): void {
        if (this.dynamicDialogConfig.data !== undefined) {
            this.user = this.dynamicDialogConfig.data['user'];
            // this.currentTypeUtilisateur = this.typeUtilisateurs.find( type => type.key === this.user.typeUtilisateur);
            // this.currentGenre = this.genres.find( type => type.key === this.user.genre);
        } else {
            this.user = new User();
        }
        this.profils$ = this.profilService.profils$.asObservable();
        this.createFormGroup();
    }

    createFormGroup(): void {
        this.formUser = this.fb.group({
            nom: [this.user?.nom, Validators.required],
            prenom: [this.user?.prenom, Validators.required],
            telephone: [this.user?.telephone, Validators.required],
            email: this.user?.email,
            // password: this.user?.password,
            // genre: [this.currentGenre, Validators.required],
            // typeUtilisateur: [this.currentTypeUtilisateur, Validators.required],
            // profil: [this.user.role, Validators.required],
        });
    }
    onGetUserInfos() {
        this.user.nom = this.formUser.value.nom;
        this.user.prenom = this.formUser.value.prenom;
        this.user.telephone = this.formUser.value.telephone;
        this.user.email = this.formUser.value.email;
        // this.user.genre = this.formUser.value.genre.key;
        // this.user.typeUtilisateur = this.formUser.value.typeUtilisateur.key;
        // this.user.role = this.formUser.value.role;
        this.update();
    }
    update(): void {
        this.userService.update(this.user).subscribe({
            next: response => {
                if (response.body === null || !response.body.id) {
                    this.messageService.add({ severity: 'error', detail: `Une erreur est survenue lors de la mise à jour du profil !` });
                } else {
                    this.dialogRef.close(response.body);
                }
            },
            error: err => {
                const message = err?.error?.detail ? err?.error?.detail : `Une erreur est survenue lors de la mise à jour du profil !`;
                this.messageService.add({ severity: 'error', detail: message });
            }
        });
    }

    onCloseModal() {
        this.dialogRef.close(null);
    }
}
