<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <a [routerLink]="['/']" class="app-logo py-3">
            <!-- <h1 style="color:#439259;">SIG BURKINA</h1> -->
           <img src="assets/layout/images/logo.jpg" class="w-8rem" alt="Logo">
    
            <button class="layout-sidebar-anchor p-link" type="button" (click)="anchor()"></button>
    
        </a>
    <div class="layout-menu-container">
        
        <app-menu></app-menu>
    </div>
</div>
