<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<form [formGroup]="formUser" (ngSubmit)="onGetUserInfos()">
    <div class="grid formgrid">
        <div class="col-12 md:col-6 mb-3 field p-fluid">
            <label>Nom</label>
            <input name="nom" formControlName="nom"
                   [class]="formUser.get('nom')?.invalid ? 'ng-dirty ng-invalid' : ''"
                   type="text" pInputText placeholder="Nom"/>
        </div>

        <div class="col-12 md:col-6 mb-3 field p-fluid">
            <label>Prénom(s)</label>
            <input name="prenom" formControlName="prenom"
                   [class]="formUser.get('prenom')?.invalid ? 'ng-dirty ng-invalid' : ''"
                   type="text" pInputText placeholder="prenom"/>
        </div>

        <div class="col-12 md:col-6 mb-3 field p-fluid">
            <label>Téléphone</label>
            <input name="telephone" formControlName="telephone"
                   [class]="formUser.get('telephone')?.invalid ? 'ng-dirty ng-invalid' : ''"
                   type="tel" pInputText placeholder="Ex:70000000"/>
        </div>

        <div class="col-12 md:col-6 mb-3 field p-fluid">
            <label>Email</label>
            <input name="email" formControlName="email" type="email" pInputText placeholder="Email" [readonly]="true"/>
        </div>

        <!-- <div class="col-12 md:col-6 mb-3 field p-fluid">
            <label>Genre</label>
            <p-dropdown [options]="genres"
                        [style]="{'width':'100%'}"
                        name="genre"
                        formControlName="genre"
                        placeholder="Sélectionner le genre"
                        appendTo="body"
                        optionLabel="label"
                        dataKey="key"
                        [showClear]="true">
            </p-dropdown>
        </div>

        <div class="col-12 md:col-6 mb-3 field p-fluid">
            <label>Type de compte</label>
            <p-dropdown [options]="typeUtilisateurs"
                        [style]="{'width':'100%'}"
                        name="typeUtilisateur"
                        formControlName="typeUtilisateur"
                        placeholder="Sélectionner le genre"
                        appendTo="body"
                        optionLabel="label"
                        dataKey="key"
                        [showClear]="true">
            </p-dropdown>
        </div> -->

        <div class="col-12 mb-3 field p-fluid hidden">
            <label>Profil</label>
            <p-dropdown *ngIf="profils$ | async as profils"
                        [options]="profils"
                        [style]="{'width':'100%'}"
                        name="profil"
                        formControlName="profil"
                        placeholder="Sélectionner le profil"
                        appendTo="body"
                        optionLabel="libelle"
                        dataKey="id"
                        [readonly]='true'
                        />
        </div>
        <div class="col-12 md:col-6 mb-3 field p-fluid">
            <label>Mot de passe</label>
            <input name="password" formControlName="password" type="text" pInputText placeholder="Mot de passe"/>
        </div>
        <div class="col-12 md:col-6 mb-3 field p-fluid">
            <label>Confirmer mot de passe</label>
            <input name="repassword" formControlName="repassword" type="text" pInputText placeholder="Confirmer mot de passe" />
        </div>
    </div>

    <div class="text-right mt-3">
        <button pButton pRipple type="reset" (click)="onCloseModal()" label="Annuler" class="p-button-outlined p-button-danger mr-3"></button>
        <button [disabled]="formUser.invalid" pButton pRipple type="submit" label="Enregistrer" class="p-button-outlined p-button-success"></button>
    </div>
</form>
