import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import {RouteInfos} from "../features/models/routeInfos.model";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: RouteInfos[] = [];

    ngOnInit() {
        this.model = [
            {
                label: 'Tableau de bord',
                icon: 'pi pi-home',
                authorities: [],
                items: [
                    {
                        label: 'Accueil',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/'],
                        authorities: [],
                    },
                ]
            },
            {
                label: 'Métiers',
                icon: 'pi pi-home',
                authorities: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PUBLICATION'],
                items: [
                    {
                        label: 'Articles',
                        icon: 'pi pi-file',
                        routerLink: ['/articles'],
                        authorities: ['ROLE_PUBLICATION', 'ROLE_ADMIN'], 
                    },
                    {
                        label: 'Catégories',
                        icon: 'pi pi-building',
                        routerLink: ['/categories'],
                        authorities: ['ROLE_ADMIN'],
                    },
                  {
                    label: 'Sous Catégories',
                    icon: 'pi pi-id-card',
                    routerLink: ['/sous-categorie'],
                    authorities: ['ROLE_ADMIN'],
                  },

                    {
                        label: 'Statistiques',
                        icon: 'pi pi-chart-line',
                        routerLink: ['/statistiques'],
                        authorities: ['ROLE_ADMIN'],
                    },
                ]
            },
            {
                label: 'Paramètres',
                icon: 'pi pi-cog',
                authorities: ['ROLE_ADMIN'],
                items: [
                    {
                        label: 'Utilisateurs',
                        icon: 'pi pi-users',
                        authorities: ['ROLE_ADMIN'],
                        items: [
                            {
                                label: 'Profils',
                                icon: 'pi pi-flag',
                                routerLink: ['/users/profils'],
                                authorities: [],
                            },
                            {
                                label: 'Utilisateurs',
                                icon: 'pi pi-users',
                                routerLink: ['/users'],
                                authorities: [],
                            },
                        ],
                    },
                ]
            },
        ];
    }
}
