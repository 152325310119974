import {Component, Input, OnInit} from '@angular/core';
import { LayoutService } from '../service/app.layout.service';
import {AuthService} from "../../features/components/auth/auth.service";
import {User} from "../../features/models/user.model";

import {ConfirmationService, MessageService} from "primeng/api";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { UserService } from 'src/app/features/components/users/user.service';
import { CreateUpdateUserComponent } from 'src/app/features/components/users/create-update-user/create-update-user.component';
import { UserProfileComponent } from 'src/app/features/components/users/user-profile/user-profile.component';
@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent implements OnInit {
    @Input()
    currentUser: User = new User();
    constructor(
        public layoutService: LayoutService,
        private authService: AuthService,
        private userService: UserService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private dialogService: DialogService,
        private fb: FormBuilder,
    ) {
    }

    ngOnInit() {
    }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        console.log(_val);
        this.layoutService.state.profileSidebarVisible = _val;
    }

    onLogout() {
        this.visible = false;
        this.authService.onLogout();
    }

    onUpdateProfileUserModal(user?: User) {
        const dialogRef: DynamicDialogRef = this.dialogService.open(UserProfileComponent, {
            header: `Mon Profil`,
            width: '50%',
            data: {'user':this.currentUser},
            contentStyle: { overflow: 'auto' },
            maximizable: false,
        });

        dialogRef.onClose.subscribe({
            next: (response: User) => {
                if (response !== null && response !== undefined) {
                    const message = `Votre profil a été mis à jour avec succès !`;
                    this.messageService.add({ severity: 'success', detail: message });
                }
            }
        });
    }
}
