import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {User} from "../../models/user.model";
import {UserCriteria} from "../../models/user-criteria.model";
import {MResponse} from "../../models/mResponse.model";
import {ArticleCriteria} from "../../models/metier/article-criteria.model";
import {Article} from "../../models/metier/article.model";
import {Utilisateur} from "../../models/utilisateur.model";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    readonly API_URL = environment.API_URL;

    constructor(
        private http: HttpClient,
    ) { }

    getAll(userCriteria: UserCriteria, page: number, size: number): Observable<HttpResponse<User[]>> {
        const params = this.buildUserCriteriaParams(userCriteria, page, size);
        return this.http.get<User[]>(`${this.API_URL}/utilisateurs/criteria`, { params: params, observe: "response"});
    }

    create(user: User): Observable<HttpResponse<User>> {
        return this.http.post<User>(`${this.API_URL}/utilisateurs`, user, { observe: "response"});
    }

    update(user: User): Observable<HttpResponse<User>> {
        return this.http.put<User>(`${this.API_URL}/utilisateurs/${user.id}`, user, { observe: "response"});
    }

    getOne(userId: number): Observable<HttpResponse<User>> {
        return this.http.get<User>(`${this.API_URL}/utilisateurs/${userId}`, { observe: "response"});
    }

    activateOrDeactivate(utilisateurId: number): Observable<HttpResponse<MResponse>> {
        return this.http.get<MResponse>(`${this.API_URL}/utilisateurs/activate/deactivate/${utilisateurId}`, { observe: "response"});
    }

    resetPassword(utilisateurId: number): Observable<HttpResponse<MResponse>> {
        return this.http.get<MResponse>(`${this.API_URL}/utilisateurs/password/reset/${utilisateurId}`, { observe: "response"});
    }

    delete(userId: number): Observable<HttpResponse<void>> {
        return this.http.delete<void>(`${this.API_URL}/utilisateurs/${userId}`, { observe: "response"});
    }

  findByCriteriaNew(criteria: UserCriteria, page: number, size: number): Observable<HttpResponse<Utilisateur[]>> {
    const params = this.buildUserCriteriaParams(criteria,page, size);
    return this.http.get<Utilisateur[]>(`${this.API_URL}/utilisateurs/criteria/new`, { params: params, observe: "response"});
  }

    private buildUserCriteriaParams(userCriteria: UserCriteria, page: number, size: number): HttpParams {
        let params: HttpParams = new HttpParams();
        // Set page and size
        params = page ? params.set('page', page) : params;
        params = size ? params.set('size', size) : params;

        // Set criteria values
        params = userCriteria.nom ? params.set('nom.contains', userCriteria.nom) : params;
        params = userCriteria.prenom ? params.set('prenom.contains', userCriteria.prenom) : params;
        params = userCriteria.telephone ? params.set('telephone.contains', userCriteria.telephone) : params;
        params = userCriteria.email ? params.set('email.contains', userCriteria.email) : params;

        return params;
    }
}
