import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {Profil} from "../../models/profil.model";
import {HttpClient, HttpResponse} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ProfilService {
    readonly API_URL = environment.API_URL;
    profils$: BehaviorSubject<Profil[]> = new BehaviorSubject<Profil[]>([]);
    // authorities$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    constructor(
        private http: HttpClient,
    ) {
        // this.initAuthorities();
        this.initProfils();
    }

    initProfils(): void {
        this.getAll().subscribe({
            next: response => {
                if (response.body !== null) {
                    this.profils$.next(response.body);
                }
            }
        });
    }

    // initAuthorities(): void {
    //     this.getAuthorities().subscribe({
    //         next: response => {
    //             if (response.body !== null) {
    //                 this.authorities$.next(response.body);
    //             }
    //         }
    //     });
    // }

    create(profil: Profil): Observable<HttpResponse<Profil>> {
        return this.http.post<Profil>(`${this.API_URL}/api/roles`, profil, { observe: "response"});
    }

    update(profil: Profil): Observable<HttpResponse<Profil>> {
        return this.http.put<Profil>(`${this.API_URL}/api/roles/${profil.id}`, profil, { observe: "response"});
    }

    delete(profil: Profil): Observable<HttpResponse<void>> {
        return this.http.delete<void>(`${this.API_URL}/api/roles/${profil.id}`, { observe: "response"});
    }

    getAll(): Observable<HttpResponse<Profil[]>> {
        return this.http.get<Profil[]>(`${this.API_URL}/api/roles`, { observe: "response"});
    }

    // getAuthorities(): Observable<HttpResponse<string[]>> {
    //     return this.http.get<string[]>(`${this.API_URL}/api/authorities`, { observe: "response"});
    // }
}
